import React from 'react';
import { NavLink } from 'react-router-dom';
import { ILink } from 'hooks/useProfileLinks';
import styles from './ProfileLink.module.scss';
import BackArrow from 'components/icons/BackArrow';
import classNames from 'classnames';

interface IProps {
  link: ILink;
}

const ProfileLink: React.FC<IProps> = ({ link }) => {
  return (
    <NavLink
      to={link.value}
      className={({ isActive }) => (isActive ? classNames(styles.active, styles.link) : styles.link)}
    >
      <div className="d-flex flex-row align-items-center">
        {link.icon && <link.icon />}
        <span className="mx-2">{link.label}</span>
      </div>
      <BackArrow className={styles.icon} />
    </NavLink>
  );
};

export default ProfileLink;
