/* eslint-disable @typescript-eslint/no-explicit-any */
import config from 'config';
import { INotification } from 'features/notifications/Notifications.service';
import { FieldConfig } from 'components/organisms/dynamicForm/DynamicForm';
import * as Yup from 'yup';
import { Area } from 'react-easy-crop';
import { useCallback } from 'react';
import ErrorIcon from 'components/icons/ErrorIcon';
import { toast } from 'react-toastify';
export function getYoutubeID(url: string) {
  let ID: string | string[] = '';
  const newURL = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (newURL[2] !== undefined) {
    // eslint-disable-next-line no-useless-escape
    ID = newURL[2].split(/[^0-9a-z_\-]/i);
    // eslint-disable-next-line prefer-destructuring
    ID = ID[0];
  } else {
    ID = '';
  }
  return ID;
}

export function removeEmptyKeys(data: { [key: string]: any }): { [key: string]: any } {
  const cleanObject: { [key: string]: any } = {};

  for (const key in data) {
    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty(key)) {
      const value = data[key];

      if (Array.isArray(value)) {
        const cleanedArray = value
          .map((item) => (typeof item === 'object' && !(item instanceof File) ? removeEmptyKeys(item) : item))
          .filter((item) => {
            if (typeof item === 'object') {
              return Object.keys(item).length > 0 || item instanceof File;
            }
            return item !== null && item !== undefined && item !== '';
          });

        if (cleanedArray.length > 0) {
          cleanObject[key] = cleanedArray;
        }
      } else if (value && typeof value === 'object' && !(value instanceof File) && key !== 'dateOfBirth') {
        const cleanedObject = removeEmptyKeys(value);

        if (Object.keys(cleanedObject).length > 0) {
          cleanObject[key] = cleanedObject;
        }
      } else if ((value !== null && value !== undefined && value !== '') || value instanceof File) {
        cleanObject[key] = value;
      }
    }
  }

  return cleanObject;
}
export function createYupSchema(fields: FieldConfig[], t: any) {
  const shape = fields?.reduce((acc: any, field) => {
    let validator;

    switch (field.fieldType) {
      case 'text':
      case 'text-area':
      case 'videoLink':
        validator = field.required
          ? Yup.string().required(t('validation.field', { label: field.label }))
          : Yup.string();
        break;
      case 'number':
        if (field.name === 'height' || field.name === 'weight') {
          validator = field.required
            ? Yup.number()
                .required(t('validation.field', { label: field.label }))
                .max(250, t('validation.max', { max: 250 }))
            : Yup.number().max(250, t('validation.max', { max: 250 }));
        } else {
          validator = field.required
            ? Yup.number().required(t('validation.field', { label: field.label }))
            : Yup.number();
        }
        break;
      case 'date':
        validator = field.required ? Yup.date().required(t('validation.field', { label: field.label })) : Yup.date();
        break;
      case 'select':
        validator = field.required
          ? Yup.object().required(t('validation.field', { label: field.label }))
          : Yup.object().nullable();
        break;
      case 'multi-select':
        validator = field.required
          ? Yup.array()
              .required(t('validation.field', { label: field.label }))
              .min(1, t('validation.field', { label: field.label }))
          : Yup.array();
        break;
      case 'upload':
        validator = field.required ? Yup.mixed().required(t('validation.field', { label: field.label })) : Yup.mixed();
        break;
      case 'videoFile':
        validator = field.required ? Yup.mixed().required(t('validation.field', { label: field.label })) : Yup.mixed();
        break;
      default:
        validator = Yup.mixed();
    }

    acc[field.name] = validator;
    return acc;
  }, {});

  return Yup.object().shape(shape);
}

const filterFields = (fields: any, i18n: any) => {
  const fieldsToRemove = i18n?.language === 'ar' ? ['briefEn', 'descriptionEn'] : ['briefAr', 'descriptionAr'];

  return fields?.filter((field: any) => !fieldsToRemove.includes(field.name));
};

export function processFields(fields: any, i18n: any, returnInitialValues = false) {
  const filteredFields = filterFields(fields, i18n);

  if (returnInitialValues) {
    const initialValues = filteredFields?.reduce((acc: any, field: any) => {
      let initialValue;
      switch (field.fieldType) {
        case 'text':
        case 'text-area':
        case 'videoLink':
        case 'number':
          initialValue = '';
          break;
        case 'select':
          initialValue = null;
          break;
        case 'multi-select':
          initialValue = [];
          break;
        case 'upload':
        case 'date':
        case 'videoFile':
          initialValue = undefined;
          break;
        default:
          initialValue = undefined;
      }
      acc[field.name] = initialValue;
      return acc;
    }, {});
    return initialValues;
  }

  const processedFields = filteredFields?.map((field: any) => {
    const placeholder = i18n.language === 'ar' ? field.placeholder.placeholderAR : field.placeholder.placeholderEN;
    const label = i18n.language === 'ar' ? field.label.labelAR : field.label.labelEN;
    return {
      ...field,
      placeholder,
      label,
    };
  });

  return processedFields;
}

export const concatenateArray = (array: any[]) => {
  const tempArray: string[] = [];
  array.forEach((el) => tempArray.push(el.value));

  return tempArray.join(',');
};

export const createFormData = (object: any) => {
  const formData = new FormData();

  Object.keys(object).forEach((key) => {
    const value = object[key];

    if (Array.isArray(value)) {
      if (value.every((item) => item instanceof File)) {
        value.forEach((file) => {
          formData.append(key, file);
        });
      } else if (value.every((item) => typeof item === 'object' && 'url' in item)) {
        value.forEach((file) => {
          formData.append(key, file.url);
        });
      } else {
        value.forEach((item) => {
          if (item instanceof File) {
            formData.append(key, item);
          } else if (typeof item === 'object' && 'url' in item) {
            formData.append(key, item.url);
          }
        });
      }
    } else {
      formData.append(key, value);
    }
  });
  return formData;
};

export type fileType = {
  url: string;
  fileName: string;
  order: number;
  size: number;
};

export const getFileSourceFromFiles = async (files: (fileType | File)[]) => {
  if (!Array.isArray(files)) files = [files];

  const filePromises = files.map((file) => {
    if (typeof file === 'string') return config.imageBaseUrl + file;
    if (typeof file === 'object' && 'url' in file) return config.imageBaseUrl + file.url;
    return new Promise((resolve, reject) => {
      // * if file
      if (file instanceof File) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          try {
            resolve(e?.target?.result);
          } catch (err) {
            reject(err);
          }
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(file);
      } else {
        // * if string or object
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
          const reader = new FileReader();
          reader.onload = async (e) => {
            try {
              resolve(e?.target?.result);
            } catch (err) {
              reject(err);
            }
          };
          reader.onerror = (error) => {
            reject(error);
          };
          reader.readAsDataURL(xhr.response);
        };
        const linkToReadFrom = typeof file === 'string' ? file : file[0];
        xhr.open('GET', linkToReadFrom);
        xhr.responseType = 'blob';
        xhr.send();
      }
    });
  });

  // Wait for all promises to be resolved
  const fileInfos = await Promise.all(filePromises);

  // Profit
  return fileInfos;
};

const formatDate = (date: string): string => {
  const notificationDate = new Date(date);
  const now = new Date();

  const diff = now.getTime() - notificationDate.getTime();
  const diffMinutes = Math.floor(diff / (1000 * 60));
  const diffHours = Math.floor(diff / (1000 * 60 * 60));
  const diffDays = Math.floor(diff / (1000 * 60 * 60 * 24));

  if (diffMinutes < 60) {
    return diffMinutes + 'm';
  } else if (diffHours < 24) {
    return diffHours + 'h';
  } else {
    return diffDays + 'd';
  }
};
export type GroupedNotifications = {
  today: INotification[];
  yesterday: INotification[];
  thisWeek: INotification[];
  thisMonth: INotification[];
  earlier: INotification[];
};
// Group notifications
export const groupNotifications = (notifications: INotification[]): GroupedNotifications => {
  const grouped: GroupedNotifications = {
    today: [],
    yesterday: [],
    thisWeek: [],
    thisMonth: [],
    earlier: [],
  };

  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  const startOfWeek = new Date(today);
  startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  notifications.forEach((notification) => {
    const notificationDate = new Date(notification.createdAt);
    const formattedDate = formatDate(notification.createdAt);
    const cloneNotification = { ...notification, createdAt: formattedDate };

    if (notificationDate >= today) {
      grouped.today.push(cloneNotification);
    } else if (notificationDate >= yesterday && notificationDate < today) {
      grouped.yesterday.push(cloneNotification);
    } else if (notificationDate >= startOfWeek && notificationDate < yesterday) {
      grouped.thisWeek.push(cloneNotification);
    } else if (notificationDate >= startOfMonth && notificationDate < startOfWeek) {
      grouped.thisMonth.push(cloneNotification);
    } else {
      grouped.earlier.push(cloneNotification);
    }
  });

  return grouped;
};

const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });

export default async function getCroppedImg(imageSrc: string, pixelCrop: Area): Promise<File> {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx?.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height,
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) {
        resolve(new File([blob], 'croppedImage.png', { type: 'image/png' }));
      } else {
        reject(new Error('Canvas is empty'));
      }
    }, 'image/png');
  });
}
export const showToast = (message: string) => {
  toast.error(message, { hideProgressBar: true, pauseOnHover: false, icon: ErrorIcon });
};
