import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
// import { runOneSignal } from './utils/oneSignal';
import { store, persistor } from 'store';
import ScrollToTopWrapper from 'components/hoc/ScrollToTopWrapper';
import BaseRoutes from './routes';
import config from 'config';
import GreenIcon from 'components/icons/GreenIcon';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'assets/styles/animation.css';
import './App.scss';

function App() {
  useEffect(() => () => window.localStorage.removeItem('appEmail'), []);

  useEffect(() => {
    const tag = document.createElement('script');
    tag.src = config.youtubeIFrameApi;

    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);
  });

  // useEffect(() => {
  //   runOneSignal();
  // });

  return (
    <>
      <ToastContainer icon={GreenIcon} />
      <GoogleOAuthProvider clientId={config.googleClientID as string}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <ScrollToTopWrapper>
                <BaseRoutes />
              </ScrollToTopWrapper>
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </GoogleOAuthProvider>
    </>
  );
}

export default App;
