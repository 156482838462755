/* eslint-disable @typescript-eslint/no-explicit-any */
import { googleLogout } from '@react-oauth/google';
import { endpoints } from '../../client/endpoints';
import { IOption, authenticatedApi } from 'client/authenticatedApi';
import { createFormData, removeEmptyKeys } from '../../utils/common';
import { clearToken } from 'store/slices/LoginSlice';

interface IPage {
  type: string | 'privacyPolicy' | 'termsAndConditions';
}

interface IPageResult {
  body: string;
  title: string;
}

interface IGetPageResponse {
  data: {
    server_date: Date;
    message: string;
    result: IPageResult;
  };
}
interface IResult {
  email: string;
  image: any;
  name: string;
  phoneCode: string;
  phoneNumber: string;
}

interface IGetProfileResponse {
  data: {
    server_date: Date;
    message: string;
    result: IResult;
  };
}

interface DynamicBody {
  [key: string]: any;
}

type MediaType = {
  url: string;
  fileName?: string;
  order?: number;
  size?: number;
};

function isMediaType(obj: any): obj is MediaType {
  return typeof obj.url === 'string' && typeof obj.order === 'number';
}

export const profileApi = authenticatedApi.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query<IResult, void>({
      query: () => ({
        url: endpoints.profileCRUD,
        method: 'GET',
      }),
      transformResponse: (response: IGetProfileResponse) => {
        return response?.data?.result;
      },
      keepUnusedDataFor: 0,
      providesTags: ['Profile'],
    }),
    updateProfile: builder.mutation<IResult, any>({
      query: (body) => ({
        url: endpoints.profileCRUD,
        method: 'PUT',
        body: createFormData(body),
      }),
      invalidatesTags: ['Profile'],
    }),
    deleteAccount: builder.mutation<IResult, void>({
      query: () => ({
        url: endpoints.deleteAccount,
        method: 'DELETE',
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled }): Promise<void> {
        try {
          const data = await queryFulfilled;
          if (data) {
            dispatch(clearToken());
            googleLogout();
          }
        } catch (err) {
          return;
        }
      },
    }),
    getPagePerType: builder.query<IPageResult, IPage>({
      query: (params) => ({
        url: endpoints.getPagePerType,
        method: 'GET',
        params,
      }),
      transformResponse: (response: IGetPageResponse) => {
        return response?.data?.result;
      },
      keepUnusedDataFor: 0,
    }),
    getSubmissionRequest: builder.query<IPageResult, string>({
      query: (submissionId) => ({
        url: `${endpoints.submissionsEndpoint}/${submissionId}`,
        method: 'GET',
      }),
      transformResponse: (response: IGetPageResponse) => {
        return response?.data?.result;
      },
      keepUnusedDataFor: 0,
    }),
    sendJoinTalentsRequest: builder.mutation<IResult, { body: any; id: string }>({
      query: ({ body, id: categoryId }) => {
        const newBody: DynamicBody = {};

        for (const key in body) {
          if (Object.prototype.hasOwnProperty.call(body, key)) {
            if (body[key] === '' || body[key] === undefined || body[key] === null) {
              // return;
            } else if (
              typeof body[key] === 'object' &&
              body[key] !== null &&
              'label' in body[key] &&
              'value' in body[key]
            ) {
              newBody[key] = body[key].value;
            } else if (Array.isArray(body[key])) {
              if (body[key].every((item: IOption) => typeof item === 'object' && 'label' in item && 'value' in item)) {
                newBody[key] = body[key].map((item: IOption) => item.value).join(',');
              } else {
                newBody[key] = body[key];
              }
            } else {
              newBody[key] = body[key];
            }
          }
        }
        newBody.categoryId = categoryId;

        const formData = createFormData(newBody);

        return {
          url: endpoints.submissionsEndpoint,
          method: 'POST',
          body: formData,
        };
      },
    }),
    editJoinTalentsRequest: builder.mutation<IResult, { body: any; id: string; categoryId: string }>({
      query: ({ body, id: submissionId, categoryId }) => {
        const newBody: DynamicBody = {};

        for (const key in body) {
          if (Object.prototype.hasOwnProperty.call(body, key)) {
            if (body[key] === '' || body[key] === undefined || body[key] === null) {
              // return;
            } else if (
              typeof body[key] === 'object' &&
              body[key] !== null &&
              'label' in body[key] &&
              'value' in body[key]
            ) {
              newBody[key] = body[key].value;
            } else if (Array.isArray(body[key])) {
              if (body[key].every((item: IOption) => typeof item === 'object' && 'label' in item && 'value' in item)) {
                newBody[key] = body[key].map((item: IOption) => item.value).join(',');
              } else {
                newBody[key] = body[key];
              }
            } else if (isMediaType(body[key])) {
              if (body[key].url) newBody[key] = body[key].url;
            } else {
              newBody[key] = body[key];
            }
          }
        }

        newBody.categoryId = categoryId;

        const formData = createFormData(removeEmptyKeys(newBody));

        return {
          url: `${endpoints.submissionsEndpoint}/${submissionId}`,
          method: 'PUT',
          body: formData,
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetProfileQuery,
  useUpdateProfileMutation,
  useDeleteAccountMutation,
  useLazyGetPagePerTypeQuery,
  useSendJoinTalentsRequestMutation,
  useEditJoinTalentsRequestMutation,
  useGetSubmissionRequestQuery,
} = profileApi;
