import React, { SetStateAction } from 'react';
import { Col, Image, Modal, Row } from 'react-bootstrap';
import { isAndroid, isIOS, isMacOs, isWindows } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import styles from './SubscribeModal.module.scss';
import CloseIcon1 from 'components/icons/CloseIcon2';
import MBCPremium from 'assets/images/mbcPremium.svg';
import ReadMoreIcon from 'components/icons/ReadMoreIcon';
import InterestRequestIcon from 'components/icons/InterestRequestIcon';
import SVGButton from 'components/atoms/SVGButton/SVGButton';
import classNames from 'classnames';

interface IProps {
  show: boolean;
  setShow: React.Dispatch<SetStateAction<boolean>>;
}
const SubscribeModal: React.FC<IProps> = ({ show, setShow }) => {
  const { t } = useTranslation('profile');

  const onClick = () => {
    if (isAndroid || isWindows) {
      // return (window.location.href = 'https://play.google.com/store/apps/details?id=com.aaib');
    }
    if (isIOS || isMacOs) {
      // return (window.location.href = 'https://apps.apple.com/eg/app/aaib-mobile/id1591020071');
    }
    return;
  };

  return (
    <Modal
      show={show}
      size="lg"
      centered
      fullscreen="md-down"
      contentClassName={styles.subscribeModalContent}
      backdropClassName="custom-modal-backdrop"
      dialogClassName="custom-modal-dialog"
    >
      <div className={styles.wrapper}>
        <div className={styles.closeButton} onClick={() => setShow(false)}>
          <CloseIcon1 />
        </div>
        <div>
          <Image src={MBCPremium} className={classNames(styles.image, 'no-rotate')} />
        </div>
        <div className={styles.card}>
          <div className={styles.title}>{t('upgradeForMore')}</div>
          <Row className="w-100 justify-content-around mt-3">
            <Col>
              <div className="d-flex flex-row align-items-center">
                <ReadMoreIcon />
                <div className={styles.descriptionTitle}>{t('readMoreContent')}</div>
              </div>
              <div className={styles.description}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
              </div>
            </Col>
            <Col>
              <div className="d-flex flex-row align-items-center">
                <InterestRequestIcon />
                <div className={styles.descriptionTitle}>{t('sendInterestRequest')}</div>
              </div>
              <div className={styles.description}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
              </div>
            </Col>
          </Row>
        </div>

        <SVGButton onClick={onClick} variant="none" label={t('downloadMobile')} className={styles.download} />
      </div>
    </Modal>
  );
};

export default SubscribeModal;
