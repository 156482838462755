import React from 'react';

function FilledHeartIcon({ className, onClick }: { className?: string; onClick?: () => void }) {
  return (
    <svg
      onClick={onClick}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill="#a83252"
        stroke="#a83252"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16.712 26.488l10.125-10.125c2.488-2.5 2.85-6.588.5-9.2A6.5 6.5 0 0017.9 6.9L16 8.813l-1.638-1.65c-2.5-2.488-6.587-2.85-9.2-.5A6.5 6.5 0 004.9 16.1l10.387 10.388a1.012 1.012 0 001.425 0z"
      ></path>
    </svg>
  );
}

export default FilledHeartIcon;
