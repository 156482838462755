import React from 'react';

function ErrorIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      enableBackground="new 0 0 32 32"
      overflow="visible"
      viewBox="0 0 32 32"
    >
      <g>
        <g>
          <circle cx="16" cy="16" r="16" fill="#D72828"></circle>
          <path fill="#E6E6E6" d="M14.5 25h3v-3h-3v3zm0-19v13h3V6h-3z"></path>
        </g>
      </g>
    </svg>
  );
}

export default ErrorIcon;
