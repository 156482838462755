import React from 'react';
import Modal from 'react-bootstrap/Modal';
import './CustomPopup.scss';

interface IProps {
  show: boolean;
  withCloseButton?: boolean;
  popupHeader?: JSX.Element | string;
  popupBody?: JSX.Element | string;
  popupFooter?: JSX.Element | string;
  size?: 'sm' | 'lg' | 'xl';
}

const CustomPopup: React.FC<IProps> = ({
  show,
  withCloseButton = false,
  popupHeader = undefined,
  popupBody = undefined,
  popupFooter = undefined,
  size = 'lg',
}) => {
  return (
    <Modal
      show={show}
      size={size}
      centered
      fullscreen="md-down"
      contentClassName="modal-popup-content"
      backdropClassName="custom-modal-backdrop"
      dialogClassName="custom-modal-dialog"
    >
      {popupHeader ? (
        <Modal.Header closeButton={withCloseButton}>
          <Modal.Title>{popupHeader}</Modal.Title>
        </Modal.Header>
      ) : undefined}
      {popupBody ? <Modal.Body>{popupBody}</Modal.Body> : undefined}
      {popupFooter ? <Modal.Footer>{popupFooter}</Modal.Footer> : undefined}
    </Modal>
  );
};

export default CustomPopup;
