const local = 'LOCAL';
const dev = 'DEV';
const uat = 'UAT';
const prod = 'PROD';

const config = {
  env: process.env.REACT_APP_ENV,
  environments: {
    local,
    dev,
    uat,
    prod,
  },

  isLocalEnv: process.env.REACT_APP_ENV === local,
  isDevEnv: process.env.REACT_APP_ENV === dev,
  apiBaseUrl: process.env.REACT_APP_URL,
  websiteBaseUrl: process.env.REACT_APP_WEBSITE_URL,
  appTimeout: process.env.REACT_APP_TIMEOUT,
  geoCodeKey: process.env.REACT_APP_GEOCODE_KEY,
  geoBaseUrl: 'https://api.opencagedata.com',
  googleClientID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  facebookAppID: process.env.REACT_APP_FACEBOOK_APP_ID,
  imageBaseUrl: process.env.REACT_APP_IMAGE_BASE_URL,
  appleClientID: process.env.REACT_APP_APPLE_KEY,
  youtubeIFrameApi: 'https://www.youtube.com/iframe_api',
  // oneSignalAppId: '9623e178-fa01-4511-b3cc-ff694354dc78',
};

export default config;
