function CreditCardIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none" viewBox="0 0 20 21">
      <path
        fill="#000"
        d="M16.666 3.833H3.333c-.92 0-1.667.748-1.667 1.667v10c0 .92.748 1.667 1.667 1.667h13.333c.92 0 1.667-.748 1.667-1.667v-10c0-.92-.748-1.667-1.667-1.667zM3.333 5.5h13.333v1.667H3.333V5.5zm0 10v-5h13.334v5H3.334z"
      ></path>
      <path fill="#000" d="M5 12.167h5v1.666H5v-1.666z"></path>
    </svg>
  );
}

export default CreditCardIcon;
