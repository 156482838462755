import { Suspense } from 'react';
import { Outlet, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import MBCLogoBlack from 'assets/images/MBCLogoBlack.svg';
import styles from './AuthTemplate.module.scss';
import EmptyHeartIcon from 'components/icons/EmptyHeartIcon';
import { useAppSelector } from 'hooks/useAppSelector';
import UserIcon from 'components/icons/UserIcon';
import { notificationsURL, profileSettingsURL, routeNames, joinTalentsURL, editTalentsURL } from 'hooks/useRoutes';
import FilledHeartIcon from 'components/icons/FilledHeartIcon';
import BellIcon from 'components/icons/Bell';
import BellIconFilled from 'components/icons/BellFill';
import { useCheckSubscriptionQuery } from 'client/authenticatedApi';
import { useGetProfileQuery } from 'features/profile/Profile.service';

function AuthTemplate() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isProfileSettingsPage = useMatch(profileSettingsURL);
  const isJoinTalent = useMatch(joinTalentsURL);
  const isEditTalent = useMatch(editTalentsURL);
  const isNotificationPage = useMatch(notificationsURL);
  const { isAuthenticated } = useAppSelector((s) => s.auth);

  const { data: profileData } = useGetProfileQuery();

  useCheckSubscriptionQuery();

  return (
    <div
      className={classNames(
        styles.wrapper,
        (isProfileSettingsPage || isNotificationPage || isJoinTalent || isEditTalent) && styles.greyBackground,
      )}
    >
      {isAuthenticated && (
        <div className={styles.header}>
          <img
            src={MBCLogoBlack}
            alt="logo"
            className={classNames('no-rotate', styles.logo)}
            onClick={() => navigate(routeNames.category)}
          />
          <div className={styles.profile}>
            <div onClick={() => navigate(routeNames.aboutUs)} className={styles.aboutus}>
              {t('aboutUs')}
            </div>
            <div className={styles.verticalSeperator} />

            {location.pathname === routeNames.favorites ? (
              <FilledHeartIcon className={styles.iconFav} onClick={() => navigate(routeNames.favorites)} />
            ) : (
              <EmptyHeartIcon className={styles.iconFav} onClick={() => navigate(routeNames.favorites)} />
            )}
            {location.pathname === routeNames.notifications ? (
              <BellIconFilled className={styles.icon} onClick={() => navigate(routeNames.notifications)} />
            ) : (
              <BellIcon className={styles.icon} onClick={() => navigate(routeNames.notifications)} />
            )}
            <div className={styles.verticalSeperator} />
            <div className={styles.name}>{t('headerProfile', { name: profileData?.name })}</div>
            <UserIcon className={styles.profileIcon} onClick={() => navigate(routeNames.profile)} />
          </div>
        </div>
      )}
      <div className={classNames(location.pathname === routeNames.category, styles.content)}>
        <Suspense>
          <Outlet />
        </Suspense>
      </div>
    </div>
  );
}

export default AuthTemplate;
