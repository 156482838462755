import { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRoutes, { routeNames } from 'hooks/useRoutes';
import NonAuthTemplate from 'templates/nonAuthTemplate/NonAuthTemplate';
import { useAppSelector } from 'hooks/useAppSelector';
import AuthTemplate from 'templates/authTemplate/AuthTemplate';
import TermsAndPrivacy from 'features/termsAndPrivacy/TermsAndPrivacy';

const ForgotPassword = lazy(() => import('features/forgotPassword/ForgotPassword'));
const ResetPassword = lazy(() => import('features/resetPassword/ResetPassword'));
const Login = lazy(() => import('features/login/Login'));
const SignUp = lazy(() => import('features/signUp/SignUp'));

const BaseRoutes = () => {
  const { i18n } = useTranslation();
  const routes = useRoutes()();
  const { isAuthenticated } = useAppSelector((s) => s.auth);

  document.body.dir = i18n.dir();

  return (
    <Routes>
      {isAuthenticated ? (
        <>
          <Route element={<AuthTemplate />}>
            {routes.map((route) => {
              if (route?.children && route.component && route?.enabled) {
                return (
                  <Route key={route?.children[0].path} element={<route.component />}>
                    {route?.children?.map(
                      (el) =>
                        el.component &&
                        el?.enabled && <Route key={el.path} path={el.path} element={<el.component />} />,
                    )}
                    <Route
                      path={`${route.path}/*`}
                      element={<Navigate to={route.children[0].path as string} replace />}
                    />
                  </Route>
                );
              }
              if (route?.component && route?.enabled) {
                return <Route key={route.path} path={route.path} element={<route.component />} />;
              }
            })}
          </Route>
          <Route path="*" element={<Navigate to={routes[0].path as string} replace />} />
        </>
      ) : (
        <Route element={<NonAuthTemplate />}>
          <Route path={routeNames.login} element={<Login />} />
          <Route path={routeNames.forgotPassword} element={<ForgotPassword />} />
          <Route path={routeNames.resetPassword} element={<ResetPassword />} />
          <Route path={routeNames.signUp} element={<SignUp />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Route>
      )}
      <Route element={<AuthTemplate />}>
        <Route path={routeNames.termsAndConditions} element={<TermsAndPrivacy />} />
        <Route path={routeNames.privacyPolicy} element={<TermsAndPrivacy />} />
      </Route>
    </Routes>
  );
};
export default BaseRoutes;
