import React from 'react';

function BellIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none" viewBox="0 0 20 21">
      <path
        stroke="#363D4E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4.39 8.625A5.617 5.617 0 0110.04 3c3.094.024 5.57 2.594 5.57 5.695v.555c0 2.797.586 4.422 1.102 5.313a.624.624 0 01-.54.937H3.829a.625.625 0 01-.54-.937c.516-.891 1.102-2.516 1.102-5.313v-.625z"
      ></path>
      <path
        stroke="#363D4E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7.5 15.5v.625a2.5 2.5 0 005 0V15.5"
      ></path>
    </svg>
  );
}

export default BellIcon;
