import React from 'react';

function UserIcon({ onClick, className }: { className: string; onClick: () => void }) {
  return (
    <svg
      onClick={onClick}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        stroke="#666"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M12.5 15.625a6.25 6.25 0 100-12.5 6.25 6.25 0 000 12.5z"
      ></path>
      <path
        stroke="#666"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3.027 21.094a10.938 10.938 0 0118.946 0"
      ></path>
    </svg>
  );
}

export default UserIcon;
