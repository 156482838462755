import React from 'react';

function Submission() {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.4375 13.3123C13.4375 13.561 13.3387 13.7994 13.1629 13.9752C12.9871 14.1511 12.7486 14.2498 12.5 14.2498H7.5C7.25136 14.2498 7.0129 14.1511 6.83709 13.9752C6.66127 13.7994 6.5625 13.561 6.5625 13.3123C6.5625 13.0637 6.66127 12.8252 6.83709 12.6494C7.0129 12.4736 7.25136 12.3748 7.5 12.3748H12.5C12.7486 12.3748 12.9871 12.4736 13.1629 12.6494C13.3387 12.8252 13.4375 13.0637 13.4375 13.3123ZM12.5 9.24983H7.5C7.25136 9.24983 7.0129 9.3486 6.83709 9.52441C6.66127 9.70023 6.5625 9.93869 6.5625 10.1873C6.5625 10.436 6.66127 10.6744 6.83709 10.8502C7.0129 11.0261 7.25136 11.1248 7.5 11.1248H12.5C12.7486 11.1248 12.9871 11.0261 13.1629 10.8502C13.3387 10.6744 13.4375 10.436 13.4375 10.1873C13.4375 9.93869 13.3387 9.70023 13.1629 9.52441C12.9871 9.3486 12.7486 9.24983 12.5 9.24983ZM17.1875 4.24983V17.3748C17.1875 17.7892 17.0229 18.1867 16.7299 18.4797C16.4368 18.7727 16.0394 18.9373 15.625 18.9373H4.375C3.9606 18.9373 3.56317 18.7727 3.27015 18.4797C2.97712 18.1867 2.8125 17.7892 2.8125 17.3748V4.24983C2.8125 3.83543 2.97712 3.438 3.27015 3.14497C3.56317 2.85195 3.9606 2.68733 4.375 2.68733H7.07266C7.451 2.29224 7.90541 1.97783 8.40851 1.76303C8.9116 1.54823 9.45297 1.4375 10 1.4375C10.547 1.4375 11.0884 1.54823 11.5915 1.76303C12.0946 1.97783 12.549 2.29224 12.9273 2.68733H15.625C16.0394 2.68733 16.4368 2.85195 16.7299 3.14497C17.0229 3.438 17.1875 3.83543 17.1875 4.24983ZM7.83516 5.18733H12.1648C12.0894 4.66702 11.829 4.1913 11.4315 3.84725C11.0339 3.5032 10.5258 3.31385 10 3.31385C9.47425 3.31385 8.96608 3.5032 8.56853 3.84725C8.17097 4.1913 7.91063 4.66702 7.83516 5.18733ZM15.3125 4.56233H13.9523C14.0253 4.8695 14.0622 5.18411 14.0625 5.49983V6.12483C14.0625 6.37347 13.9637 6.61192 13.7879 6.78774C13.6121 6.96355 13.3736 7.06233 13.125 7.06233H6.875C6.62636 7.06233 6.3879 6.96355 6.21209 6.78774C6.03627 6.61192 5.9375 6.37347 5.9375 6.12483V5.49983C5.93776 5.18411 5.97472 4.8695 6.04766 4.56233H4.6875V17.0623H15.3125V4.56233Z"
        fill="#000"
      />
    </svg>
  );
}

export default Submission;
