import React from 'react';

function GlobeIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill="#000"
        d="M12 3a9 9 0 109 9 9.01 9.01 0 00-9-9zM9.718 15.461h4.564c-.465 1.588-1.243 3.018-2.282 4.145-1.039-1.127-1.817-2.557-2.282-4.145zm-.314-1.384a12.61 12.61 0 010-4.154h5.192a12.61 12.61 0 010 4.154H9.404zM4.384 12c0-.702.097-1.401.289-2.077H8a14 14 0 000 4.154H4.673A7.582 7.582 0 014.385 12zm9.898-3.462H9.718c.465-1.587 1.243-3.017 2.282-4.144 1.039 1.127 1.817 2.557 2.282 4.144zm1.717 1.385h3.328a7.629 7.629 0 010 4.154H16a13.997 13.997 0 000-4.154zm2.783-1.385h-3.066a12.323 12.323 0 00-1.753-3.894 7.648 7.648 0 014.819 3.894zm-8.745-3.894a12.322 12.322 0 00-1.753 3.894H5.218a7.647 7.647 0 014.82-3.894zM5.218 15.461h3.066c.353 1.39.947 2.709 1.753 3.895a7.648 7.648 0 01-4.819-3.895zm8.745 3.895a12.323 12.323 0 001.753-3.895h3.066a7.646 7.646 0 01-4.82 3.895z"
      ></path>
    </svg>
  );
}

export default GlobeIcon;
