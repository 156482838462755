/* eslint-disable @typescript-eslint/no-explicit-any */
import { endpoints } from './endpoints';
import i18next from 'i18next';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setLoginType, setToken } from 'store/slices/LoginSlice';
import config from '../config';

export interface IFacebookResponse {
  accessToken: string;
  data_access_expiration_time: number;
  email: string;
  expiresIn: number;
  graphDomain: string;
  id: string;
  name: string;
  picture: any;
  signedRequest: string;
  userID: string;
}

export interface IAppleResponse {
  authorization: {
    code: string;
    id_token: string;
    state: string;
  };
}

export interface IRegister {
  name: string;
  email: string;
  phoneCode: string;
  phoneNumber: string;
  password: string;
}
export interface IForgot {
  email: string;
}

export interface IOTP extends IForgot {
  token: string;
}

export interface ISignUpOTP extends IForgot {
  otp: string;
}

export interface IReset extends IOTP {
  password: string;
}

export interface ILogin {
  loginType: string;
  appEmail: string;
  appPasscode: string;
}

export interface IAuthResponse {
  data: {
    server_date: Date;
    message: string;
    result: {
      _id: string;
      access_token: string;
      expires_in: number;
      refresh_token: string;
      refresh_token_header: string;
      isDefaultPassword: boolean;
      name: string;
      email: string;
    };
  };
}

export const serializeToFormUrlEncoded = (params: object) =>
  Object.entries(params)
    .map((entry) => `${encodeURIComponent(entry[0])}=${encodeURIComponent(entry[1])}`)
    .join('&');

export const geoLocationApi = createApi({
  reducerPath: 'geoLocationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.geoBaseUrl,
    timeout: 30000,
  }),
  endpoints: (builder) => ({
    getLocation: builder.query({
      query: ({ longitude, latitude }) => ({
        url: endpoints.getLocation,
        method: 'GET',
        params: {
          key: config.geoCodeKey,
          q: `${latitude},${longitude}`,
          pretty: 1,
        },
      }),
    }),
  }),
  refetchOnReconnect: true,
});

export const nonAuthApi = createApi({
  reducerPath: 'nonAuthApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.apiBaseUrl,
    prepareHeaders: async (headers) => {
      headers.set('Accept-Language', i18next.language);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    login: builder.mutation<IAuthResponse, ILogin>({
      query: (body) => {
        const encodedBody = serializeToFormUrlEncoded(body);
        return {
          url: endpoints.login,
          method: 'POST',
          body: encodedBody,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          },
        };
      },
      async onQueryStarted(body, { dispatch, queryFulfilled }): Promise<void> {
        try {
          const { data: result } = await queryFulfilled;
          dispatch(setToken(result));
        } catch (err) {
          return;
        }
      },
    }),
    forgotPassword: builder.mutation<any, IForgot>({
      query: (body) => {
        return {
          url: endpoints.forgotPassword,
          method: 'PUT',
          body,
        };
      },
    }),
    checkOTP: builder.mutation<any, IOTP>({
      query: (body) => {
        return {
          url: endpoints.checkOTP,
          method: 'PUT',
          body,
        };
      },
    }),
    resendOTP: builder.mutation<any, IForgot>({
      query: (body) => {
        return {
          url: endpoints.resendOTP,
          method: 'POST',
          body,
        };
      },
    }),
    resetPassword: builder.mutation<any, IReset>({
      query: (body) => {
        return {
          url: endpoints.resetPassword,
          method: 'PUT',
          body,
        };
      },
    }),
    signUp: builder.mutation<any, IRegister>({
      query: (body) => {
        return {
          url: endpoints.signUp,
          method: 'POST',
          body,
        };
      },
    }),
    checkSignUpOTP: builder.mutation<any, ISignUpOTP>({
      query: (body) => {
        return {
          url: endpoints.checkSignUpOTP,
          method: 'POST',
          body,
        };
      },
    }),
    loginWithGoogle: builder.mutation<any, Pick<IOTP, 'token'>>({
      query: (body) => {
        return {
          url: endpoints.loginWithGoogle,
          method: 'POST',
          body,
        };
      },
      async onQueryStarted(body, { dispatch, queryFulfilled }): Promise<void> {
        try {
          const { data: result } = await queryFulfilled;
          dispatch(setToken(result));
          dispatch(setLoginType('google'));
        } catch (err) {
          return;
        }
      },
    }),
    loginWithFacebook: builder.mutation<any, Pick<IOTP, 'token'>>({
      query: (body) => {
        return {
          url: endpoints.loginWithFacebook,
          method: 'POST',
          body,
        };
      },
      async onQueryStarted(body, { dispatch, queryFulfilled }): Promise<void> {
        try {
          const { data: result } = await queryFulfilled;
          dispatch(setToken(result));
          dispatch(setLoginType('facebook'));
        } catch (err) {
          return;
        }
      },
    }),
    loginWithApple: builder.mutation<any, Pick<IOTP, 'token'>>({
      query: (body) => {
        return {
          url: endpoints.loginWithApple,
          method: 'POST',
          body,
        };
      },
      async onQueryStarted(body, { dispatch, queryFulfilled }): Promise<void> {
        try {
          const { data: result } = await queryFulfilled;
          dispatch(setToken(result));
          dispatch(setLoginType('apple'));
        } catch (err) {
          return;
        }
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useForgotPasswordMutation,
  useCheckOTPMutation,
  useResetPasswordMutation,
  useResendOTPMutation,
  useSignUpMutation,
  useCheckSignUpOTPMutation,
  useLoginWithGoogleMutation,
  useLoginWithFacebookMutation,
  useLoginWithAppleMutation,
} = nonAuthApi;

export const { useLazyGetLocationQuery } = geoLocationApi;
