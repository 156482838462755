import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import { authenticatedApi } from 'client/authenticatedApi';
import { geoLocationApi, nonAuthApi } from 'client/nonAuthenticatedApi';
import authSlice from 'store/slices/LoginSlice';
import subscriptionSlice from 'store/slices/SubscriptionSlice';

const reducer = combineReducers({
  [geoLocationApi.reducerPath]: geoLocationApi.reducer,
  [nonAuthApi.reducerPath]: nonAuthApi.reducer,
  [authenticatedApi.reducerPath]: authenticatedApi.reducer,
  auth: authSlice,
  subscription: subscriptionSlice,
});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(authenticatedApi.middleware)
      .concat(nonAuthApi.middleware)
      .concat(geoLocationApi.middleware),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
