import React from 'react';
import Button from 'react-bootstrap/Button';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { DefaultTFuncReturn } from 'i18next';
import './SVGButton.scss';

interface IProps {
  label?: string | DefaultTFuncReturn;
  onClick: () => void;
  variant?: string;
  ComponentSvg?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  href?: string;
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
  styles?: React.CSSProperties;
  type?: 'button' | 'submit' | 'reset';
  subtitle?: string;
  active?: boolean;
}

const SVGButton: React.FC<IProps> = ({
  label,
  onClick,
  ComponentSvg,
  variant,
  href,
  isLoading,
  styles,
  disabled,
  className,
  type,
  subtitle,
  active,
}) => {
  const classes = `${active ? 'active' : ''}${
    label ? '' : 'no-margin'
  } svg-button d-flex align-items-center justify-content-center ${className}`;
  const renderChild = () => (
    <>
      {ComponentSvg && !isLoading && (
        <div>
          <ComponentSvg />
        </div>
      )}
      {isLoading && (
        <span>
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
        </span>
      )}
      <div className={classnames(subtitle && 'd-flex flex-column align-items-start')}>
        <div className="title">{label}</div>
        {subtitle && <div className="subtitle"> {subtitle}</div>}
      </div>
    </>
  );
  return href ? (
    <Link to={href} className={`${classes} btn btn-${variant} ${className}`}>
      {renderChild()}
    </Link>
  ) : (
    <Button
      disabled={isLoading || disabled}
      variant={variant}
      style={styles}
      className={classes}
      onClick={onClick}
      type={type}
    >
      {renderChild()}
    </Button>
  );
};

SVGButton.defaultProps = {
  variant: 'outline-primary',
  isLoading: false,
  onClick: undefined,
  href: undefined,
  ComponentSvg: undefined,
  disabled: false,
  className: '',
  styles: undefined,
  label: '',
  type: undefined,
  subtitle: '',
  active: false,
};

export default React.memo(SVGButton);
