import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export interface SubState {
  freeSubscription?: boolean;
  paidSubscription?: {
    createdAt: Date;
    currency: string;
    duration: number;
    durationUnit: string;
    expiryDate: Date;
    isRenew: false;
    isRestored: false;
    packageId: string;
    packageName: string;
    packageOriginalPrice: number;
    price: number;
    status: string;
    type: string;
    _id: string;
  };
}

const persistConfig = {
  key: 'subscription',
  storage,
};

const initialState: SubState = {
  freeSubscription: false,
  paidSubscription: undefined,
};

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: initialState,
  reducers: {
    setSubscription: (state, action) => {
      if (isEmpty(action.payload)) {
        state.freeSubscription = false;
        state.paidSubscription = undefined;
      } else if (action.payload.freeSubscription) {
        state.freeSubscription = action.payload.freeSubscription;
        state.paidSubscription = undefined;
      } else {
        state.paidSubscription = action.payload;
      }
    },
    clearSubscription: (state) => {
      state.freeSubscription = false;
      state.paidSubscription = undefined;
    },
  },
});

const persistedSubSlice = persistReducer(persistConfig, subscriptionSlice.reducer);
export const { setSubscription, clearSubscription } = subscriptionSlice.actions;
export default persistedSubSlice;
