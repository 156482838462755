const Bell = ({ className, onClick }: { className?: string; onClick?: () => void }) => {
  return (
    <svg
      onClick={onClick}
      className={className}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.26855 10.2501C5.2673 9.36096 5.44197 8.48035 5.78251 7.65901C6.12305 6.83766 6.62272 6.0918 7.25275 5.46438C7.88277 4.83697 8.6307 4.34041 9.45346 4.00329C10.2762 3.66617 11.1575 3.49516 12.0467 3.5001C15.7592 3.52823 18.731 6.6126 18.731 10.3345V11.0001C18.731 14.3564 19.4342 16.3064 20.0529 17.3751C20.1186 17.4889 20.1533 17.618 20.1534 17.7494C20.1535 17.8809 20.1191 18.01 20.0536 18.124C19.9881 18.2379 19.8938 18.3326 19.7801 18.3986C19.6665 18.4647 19.5375 18.4996 19.406 18.5001H4.59355C4.46212 18.4996 4.33312 18.4647 4.21948 18.3986C4.10583 18.3326 4.01153 18.2379 3.94601 18.124C3.8805 18.01 3.84608 17.8809 3.84619 17.7494C3.84631 17.618 3.88096 17.4889 3.94667 17.3751C4.56542 16.3064 5.26855 14.3564 5.26855 11.0001V10.2501Z"
        stroke="#363D4E"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 18.5V19.25C9 20.0456 9.31607 20.8087 9.87868 21.3713C10.4413 21.9339 11.2044 22.25 12 22.25C12.7956 22.25 13.5587 21.9339 14.1213 21.3713C14.6839 20.8087 15 20.0456 15 19.25V18.5"
        stroke="#363D4E"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Bell;
