import React from 'react';

function InterestRequestIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
      <path
        stroke="#363D4E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20.536 3.506L2.333 8.633a.781.781 0 00-.117 1.465l8.36 3.955a.761.761 0 01.37.37l3.955 8.36a.781.781 0 001.465-.117l5.127-18.203a.772.772 0 00-.957-.957v0zM10.83 14.17l4.414-4.414"
      ></path>
    </svg>
  );
}

export default InterestRequestIcon;
