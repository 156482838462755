import React from 'react';

function EmptyHeartIcon({ className, onClick }: { className?: string; onClick?: () => void }) {
  return (
    <svg
      onClick={onClick}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        stroke="#666"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.375"
        d="M13.056 20.694l7.91-7.91c1.944-1.954 2.227-5.147.391-7.188a5.078 5.078 0 00-7.373-.205L12.5 6.885l-1.28-1.29c-1.953-1.942-5.146-2.226-7.187-.39a5.078 5.078 0 00-.205 7.373l8.115 8.116a.79.79 0 001.113 0v0z"
      ></path>
    </svg>
  );
}

export default EmptyHeartIcon;
