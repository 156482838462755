import React from 'react';
import { CircularProgress } from '@mui/material';
import { DefaultTFuncReturn } from 'i18next';
import './PageLoader.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
  title?: string | DefaultTFuncReturn;
  isFullScreen?: boolean;
}

const PageLoader: React.FC<IProps> = ({ title, isFullScreen }) => {
  const { t } = useTranslation();

  return (
    <div className="pageLoader" style={{ height: isFullScreen ? '100vh' : '100%' }}>
      <div>
        <CircularProgress size={40} color="inherit" />
      </div>
      <div>
        <span className="title">{title ?? t('loadingTitle')}</span>
      </div>
    </div>
  );
};

PageLoader.defaultProps = {
  isFullScreen: false,
  title: undefined,
};

export default React.memo(PageLoader);
