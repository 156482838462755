import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
  refreshTokenHeader: string | null;
  isAuthenticated: boolean;
  profile?: {
    email: string;
    name: string;
  };
  loginType: string;
}

const persistConfig = {
  key: 'auth',
  storage,
};

const initialState: AuthState = {
  accessToken: null,
  refreshToken: null,
  refreshTokenHeader: null,
  isAuthenticated: false,
  profile: undefined,
  loginType: 'app',
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setToken: (state, action) => {
      state.accessToken = action.payload.access_token;
      state.refreshToken = action.payload.refresh_token;
      state.profile = {
        name: action.payload.name,
        email: action.payload.email,
      };
      state.isAuthenticated = true;
      if (action.payload.refresh_token_header) {
        state.refreshTokenHeader = action.payload.refresh_token_header;
      }
    },
    clearToken: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
      state.refreshTokenHeader = null;
      state.isAuthenticated = false;
      state.profile = undefined;
    },
    setLoginType: (state, action) => {
      state.loginType = action.payload;
    },
    updateProfileInfo: (state, action) => {
      state.profile = action.payload.profile;
    },
    clearLoginType: (state) => {
      state.loginType = 'app';
    },
  },
});

const persistedAuthSlice = persistReducer(persistConfig, authSlice.reducer);
export const { setToken, clearToken, setLoginType, clearLoginType, updateProfileInfo } = authSlice.actions;
export default persistedAuthSlice;
