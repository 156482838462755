/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { googleLogout } from '@react-oauth/google';
import { RootState } from 'store/index';
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { setToken, clearToken } from 'store/slices/LoginSlice';
import { endpoints } from './endpoints';
import { serializeToFormUrlEncoded } from 'client/nonAuthenticatedApi';
import config from '../config';
import ErrorIcon from 'components/icons/ErrorIcon';
import { setSubscription } from 'store/slices/SubscriptionSlice';

interface ILogoutResponse {
  data: {
    server_date: Date;
    message: string;
  };
}

export interface ISubHistory {
  createdAt: Date;
  currency: string;
  duration: number;
  durationUnit: string;
  expiryDate: Date;
  isRenew: boolean;
  isRestored: boolean;
  packageId: string;
  packageName: string;
  price: number;
  status: string;
  type: string;
  _id: string;
}
interface ISubHistoryResponse {
  data: {
    message: string;
    result: { history: ISubHistory[] };
    server_date: Date;
  };
}

export interface IOption {
  value: string | number;
  label: string;
}
export interface IRefresh {
  data: {
    data?: {
      message: string;
      server_date?: Date;
      result?: {
        expires_in: number;
        access_token: string;
        refresh_token: string;
      };
    };
    errorCode?: string;
  };
}
export interface ITableResponse {
  data: {
    message: string;
    server_date: string;
    result: {
      currentPage: number;
      data: object[];
      pageLimit: number;
      totalCount: number;
      totalPages: number;
    };
  };
}

export const baseQuery = fetchBaseQuery({
  baseUrl: config.apiBaseUrl,
  timeout: 30000,
  prepareHeaders: async (headers, { getState, endpoint }) => {
    const { accessToken, refreshTokenHeader } = (getState() as RootState).auth;
    headers.set('Accept-Language', i18next.language);
    const token = endpoint === 'refresh' ? refreshTokenHeader : accessToken;
    if (token) {
      headers.set('Authorization', token);
      return headers;
    }
    return headers;
  },
});

// eslint-disable-next-line @typescript-eslint/ban-types
const baseQueryWithReauth = async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && (result.error?.status === 'TIMEOUT_ERROR' || result.error.status === 'FETCH_ERROR')) {
    toast.error('Something went wrong', { hideProgressBar: true, pauseOnHover: false, icon: ErrorIcon });
  }

  if (result.error && result.error.status === 401) {
    const { refreshToken } = (api.getState() as RootState).auth;
    if (refreshToken) {
      const details = {
        ['refresh_token']: refreshToken,
      };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const refreshResult: any = await baseQuery(
        {
          url: endpoints.refreshToken,
          method: 'POST',
          body: serializeToFormUrlEncoded(details),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: '*/*',
          },
        },
        { ...api, endpoint: 'refresh' },
        extraOptions,
      );

      if (refreshResult?.data) {
        api.dispatch(setToken(refreshResult.data));
      } else if (refreshResult.error) {
        api.dispatch(clearToken());
        googleLogout();
      }

      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(clearToken());
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  } else if (result.error && result?.error?.data?.data?.message) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    toast.error(result?.error?.data?.data?.message, { hideProgressBar: true, pauseOnHover: false, icon: ErrorIcon });
  }

  return result;
};

const tagTypes = ['Categories', 'Talents', 'Favorites', 'Profile', 'Notifications', 'Submissions'] as const;

export const authenticatedApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes,
  endpoints: (builder) => ({
    checkSubscription: builder.query<any, void>({
      query: (type) => ({
        url: endpoints.checkSubscription,
        method: 'GET',
        params: {
          type,
        },
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled }): Promise<void> {
        try {
          const {
            data: {
              data: { result },
            },
          } = await queryFulfilled;
          dispatch(setSubscription(result));
        } catch (err) {
          return;
        }
      },
      keepUnusedDataFor: 0,
    }),
    getSubscriptionHistory: builder.query<any, void>({
      query: (type) => ({
        url: endpoints.checkSubscriptionHistory,
        method: 'GET',
        params: {
          type,
        },
      }),
      transformResponse: (response: ISubHistoryResponse) => {
        return response.data.result;
      },
      keepUnusedDataFor: 0,
    }),
    getLovsCountries: builder.query<IOption[], void>({
      query: () => ({
        url: endpoints.getLovsCountries,
        method: 'GET',
      }),
      transformResponse: (response: any) =>
        response.data.result.map((el: any) => ({
          value: el.id,
          label: el.name,
        })),
    }),
    getLovsCities: builder.query<IOption[], string>({
      query: (countryCode) => ({
        url: `${endpoints.getLovsCities}/${countryCode}`,
        method: 'GET',
      }),
      transformResponse: (response: any) => response.data.result,
    }),
    getLovsLanguages: builder.query<IOption[], void>({
      query: (type) => ({
        url: endpoints.getLovsLanguages,
        method: 'GET',
        params: {
          type,
        },
      }),
      transformResponse: (response: any) =>
        response.data.result.map((el: any) => ({
          value: el.value,
          label: el.title,
        })),
    }),
    getLovsEyes: builder.query<IOption[], void>({
      query: (type) => ({
        url: endpoints.getLovsEyes,
        method: 'GET',
        params: {
          type,
        },
      }),
      transformResponse: (response: any) =>
        response.data.result.map((el: any) => ({
          value: el.value,
          label: el.title,
        })),
    }),
    getLovsHair: builder.query<IOption[], void>({
      query: (type) => ({
        url: endpoints.getLovsHair,
        method: 'GET',
        params: {
          type,
        },
      }),
      transformResponse: (response: any) =>
        response.data.result.map((el: any) => ({
          value: el.value,
          label: el.title,
        })),
    }),
    getLovsTalentType: builder.query<IOption[], void>({
      query: (type) => ({
        url: endpoints.getLovsTalentTypes,
        method: 'GET',
        params: {
          type,
        },
      }),
      transformResponse: (response: any) =>
        response.data.result.map((el: any) => ({
          value: el.value,
          label: el.title,
        })),
    }),
    getLovsSkin: builder.query<IOption[], void>({
      query: (type) => ({
        url: endpoints.getLovsSkin,
        method: 'GET',
        params: {
          type,
        },
      }),
      transformResponse: (response: any) =>
        response.data.result.map((el: any) => ({
          value: el.value,
          label: el.title,
        })),
    }),
    logout: builder.mutation<ILogoutResponse, void>({
      query: () => {
        return {
          url: endpoints.logout,
          method: 'PUT',
        };
      },
    }),
    updateLanguage: builder.mutation<any, string>({
      query: (language) => {
        return {
          url: endpoints.updateLanguage,
          method: 'PUT',
          body: {
            language,
          },
        };
      },
    }),
  }),
  refetchOnReconnect: true,
});

export const {
  useGetLovsCountriesQuery,
  useLogoutMutation,
  useCheckSubscriptionQuery,
  useLazyGetSubscriptionHistoryQuery,
  useGetLovsLanguagesQuery,
  useGetLovsEyesQuery,
  useGetLovsHairQuery,
  useGetLovsSkinQuery,
  useGetLovsTalentTypeQuery,
  useLazyGetLovsCitiesQuery,
  useUpdateLanguageMutation,
} = authenticatedApi;
