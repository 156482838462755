import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface IProps {
  children: JSX.Element;
}

const ScrollToTopWrapper: React.FC<IProps> = ({ children }) => {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [pathname]);

  return children;
};

export default ScrollToTopWrapper;
