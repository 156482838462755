import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import i18n from './i18n';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

i18n.on('initialized', () => {
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
});
