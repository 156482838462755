import { useAppSelector } from 'hooks/useAppSelector';
import React, { lazy, useCallback } from 'react';
import ProfileTemplate from 'templates/profileTemplate/ProfileTemplate';

export const routeNames = {
  login: '/login',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  signUp: '/register',
  category: '/category',
  categoryById: (id?: string) => ({
    href: '/category/:id',
    as: `/category/${id}`,
  }),
  talentById: (id?: string) => ({
    href: '/talents/:id',
    as: `/talents/${id}`,
  }),
  favorites: '/favorites',
  notifications: '/notifications',
  profile: '/profileSettings/profile',
  languages: '/profileSettings/languages',
  termsAndConditions: '/termsAndConditions',
  aboutUs: '/aboutUs',
  privacyPolicy: '/privacyPolicy',
  mySubscriptions: '/profileSettings/subscriptions',
  joinTalents: '/joinTalents',
  submissions: '/profileSettings/submissions',
  submissionById: (id?: string) => ({
    href: '/profileSettings/submissions/:id',
    as: `/profileSettings/submissions/${id}`,
  }),
  requestedTalents: '/profileSettings/requestedTalents',
};

export const profileSettingsURL = '/profileSettings/:path';
export const notificationsURL = '/notifications';
export const joinTalentsURL = '/joinTalents';
export const editTalentsURL = '/profileSettings/submissions/:id';

interface IRoute {
  path?: string;
  component?: React.FunctionComponent;
  children?: IRoute[];
  enabled?: boolean;
}

const Categories = lazy(() => import('features/categories/Categories'));
const Talents = lazy(() => import('features/talents/Talents'));
const TalentDetails = lazy(() => import('features/talentDetails/TalentDetails'));
const Favorite = lazy(() => import('features/favorites/Favorites'));
const Notifications = lazy(() => import('features/notifications/Notifications'));
const Profile = lazy(() => import('features/profile/Profile'));
const Languages = lazy(() => import('features/languages/Languages'));
const TermsAndPrivacy = lazy(() => import('features/termsAndPrivacy/TermsAndPrivacy'));
const MySubscriptions = lazy(() => import('features/mySubscriptions/MySubscriptions'));
const JoinTalents = lazy(() => import('features/joinTalents/JoinTalents'));
const EditJoinTalent = lazy(() => import('features/joinTalents/EditJoinTalents'));
const Submissions = lazy(() => import('features/submissions/Submissions'));
const RequestedTalents = lazy(() => import('features/requestedTalents/RequestedTalents'));

export const useRoutes = () => {
  const { freeSubscription } = useAppSelector((s) => s.subscription);

  return useCallback(
    (): IRoute[] => [
      {
        path: routeNames.category,
        component: Categories,
        enabled: true,
      },
      {
        path: routeNames.categoryById().href,
        component: Talents,
        enabled: true,
      },
      {
        path: routeNames.talentById().href,
        component: TalentDetails,
        enabled: true,
      },
      {
        path: routeNames.favorites,
        component: Favorite,
        enabled: true,
      },
      {
        path: routeNames.notifications,
        component: Notifications,
        enabled: true,
      },
      {
        component: ProfileTemplate,
        children: [
          {
            path: routeNames.profile,
            component: Profile,
            enabled: true,
          },
          {
            path: routeNames.languages,
            component: Languages,
            enabled: true,
          },
          {
            path: routeNames.mySubscriptions,
            component: MySubscriptions,
            enabled: !freeSubscription,
          },

          {
            path: routeNames.submissions,
            component: Submissions,
            enabled: true,
          },
          {
            path: routeNames.requestedTalents,
            component: RequestedTalents,
            enabled: true,
          },
        ],
        enabled: true,
      },
      {
        path: routeNames.joinTalents,
        component: JoinTalents,
        enabled: true,
      },
      {
        path: routeNames.submissionById().href,
        component: EditJoinTalent,
        enabled: true,
      },
      {
        path: routeNames.termsAndConditions,
        component: TermsAndPrivacy,
        enabled: true,
      },
      {
        path: routeNames.privacyPolicy,
        component: TermsAndPrivacy,
        enabled: true,
      },
      {
        path: routeNames.aboutUs,
        component: TermsAndPrivacy,
        enabled: true,
      },
    ],
    [freeSubscription],
  );
};

export default useRoutes;
