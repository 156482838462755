import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import BackArrow from 'components/icons/BackArrow';
import { routeNames } from 'hooks/useRoutes';
import styles from './TermsAndPrivacy.module.scss';
import { useLazyGetPagePerTypeQuery } from 'features/profile/Profile.service';
import PageLoader from 'components/atoms/pageLoader/PageLoader';

const TermsAndPrivacy = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('profile');
  const location = useLocation();

  const [getPage, { data, isLoading }] = useLazyGetPagePerTypeQuery();

  useEffect(() => {
    if (
      location.pathname?.slice(1) === 'privacyPolicy' ||
      location.pathname?.slice(1) === 'termsAndConditions' ||
      location.pathname?.slice(1) === 'aboutUs'
    ) {
      getPage({ type: location.pathname?.slice(1) });
    }
  }, [getPage, location.pathname]);

  return (
    <div className="d-flex flex-column justify-content-between h-100">
      {!data || isLoading ? (
        <PageLoader />
      ) : (
        <div>
          <Row className={styles.title}>
            <BackArrow onClick={() => navigate(routeNames.profile)} className={styles.backArrow} />
            {data.title}
          </Row>
          <Row className="mt-4">
            <Col className={styles.outletWrapper}>{parse(data.body)}</Col>
          </Row>
        </div>
      )}
      <Row className="w-100 justify-content-between">
        <Col className={styles.copyright}>{t('copyright')}</Col>
        <Row className="row-reverse">
          <div className={styles.termsPolicy} onClick={() => navigate(routeNames.termsAndConditions)}>
            {t('termsAndConditions')}
          </div>
          <div className={styles.privacyPolicy} onClick={() => navigate(routeNames.privacyPolicy)}>
            {t('privacyPolicy')}
          </div>
        </Row>
      </Row>
    </div>
  );
};

export default TermsAndPrivacy;
