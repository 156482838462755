import React from 'react';

function LogoutIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      fill="none"
      viewBox="0 0 20 21"
    >
      <path
        fill="#C63A38"
        fillRule="evenodd"
        d="M11.124 3.472a6.903 6.903 0 11-3.451 12.882.986.986 0 10-.986 1.708 8.875 8.875 0 100-15.373.986.986 0 10.986 1.708 6.903 6.903 0 013.451-.925z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#C63A38"
        fillRule="evenodd"
        d="M5.824 4.675c.425.34.494.96.154 1.386L3.315 9.389h6.823a.986.986 0 010 1.973H3.315l2.663 3.328a.986.986 0 11-1.54 1.232L0 10.376l4.437-5.547a.986.986 0 011.387-.154z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default LogoutIcon;
