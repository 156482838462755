import React, { Suspense, useCallback, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { googleLogout } from '@react-oauth/google';
import { Col, Image, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useProfileLinks } from 'hooks/useProfileLinks';
import ProfileLink from 'components/atoms/profileLink/ProfileLink';
import BackArrow from 'components/icons/BackArrow';
import { routeNames } from 'hooks/useRoutes';
import SVGButton from 'components/atoms/SVGButton/SVGButton';
import LogoutIcon from 'components/icons/LogoutIcon';
import { useAppSelector } from 'hooks/useAppSelector';
import styles from './ProfileTemplate.module.scss';
import { clearToken } from 'store/slices/LoginSlice';
import MBCPremium from 'assets/images/mbcPremium.svg';
import CustomPopup from 'components/molecules/customPopup/CustomPopup';
import SubscribeModal from 'components/molecules/subscribeModal/SubscribeModal';

function ProfileTemplate() {
  const navigate = useNavigate();
  const { t } = useTranslation('profile');
  const links = useProfileLinks();
  const dispatch = useDispatch();
  const { loginType } = useAppSelector((s) => s.auth);
  const { freeSubscription, paidSubscription } = useAppSelector((s) => s.subscription);

  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState<boolean>(false);

  const handleLogout = useCallback(() => {
    if (loginType === 'google') {
      googleLogout();
    }
    dispatch(clearToken());
  }, [dispatch, loginType]);

  return (
    <>
      <SubscribeModal show={showSubscriptionModal} setShow={setShowSubscriptionModal} />
      <CustomPopup
        show={showLogoutModal}
        popupBody={
          <div className={styles.deleteWrapper}>
            <div className={styles.title}>{t('logoutConfirmationTitle')}</div>
          </div>
        }
        popupFooter={
          <div className={styles.deleteButtonWrappers}>
            <SVGButton
              onClick={() => setShowLogoutModal(false)}
              label={t('cancel')}
              variant="none"
              className={styles.cancel}
            />
            <SVGButton onClick={handleLogout} label={t('logout')} variant="none" className={styles.delete} />
          </div>
        }
      />
      <div className="d-flex flex-column justify-content-between h-100">
        <div>
          <Row className={styles.title}>
            <BackArrow onClick={() => navigate(routeNames.category)} className={styles.backArrow} />
            {t('profileSettings')}
          </Row>
          <Row className="mt-3 g-0">
            <Col xs={12} md={3}>
              <div className={styles.linksWrapper}>
                {links.map((link) => link.enabled && <ProfileLink key={link.value} link={link} />)}
                <SVGButton
                  onClick={() => setShowLogoutModal(true)}
                  variant="none"
                  label={t('logout')}
                  className={styles.logout}
                  ComponentSvg={LogoutIcon}
                />
              </div>

              {!freeSubscription && !paidSubscription && (
                <div className={styles.subscriptionBanner}>
                  <Col xs={6} md={6} className="d-flex flex-column h-100 justify-content-between align-items-start p-3">
                    <div className={styles.subscriptionTitle}>{t('upgradeForMore')}</div>
                    <div className={styles.subscriptionSubtitle}>{t('goPremium')}</div>
                    <SVGButton
                      onClick={() => setShowSubscriptionModal(true)}
                      variant="none"
                      label={t('subscribe')}
                      className={styles.subscribe}
                    />
                  </Col>
                  <Col xs={6} md={6} className={styles.logoWrapper}>
                    <Image src={MBCPremium} />
                  </Col>
                </div>
              )}
            </Col>
            <Col xs={12} md={8}>
              <div className={styles.outletWrapper}>
                <Suspense>
                  <Outlet />
                </Suspense>
              </div>
            </Col>
          </Row>
        </div>
        <Row className={styles.footer}>
          <Col className={styles.copyright}>{t('copyright')}</Col>
          <Row className={styles.termsAndPolicy}>
            <div className={styles.termsPolicy} onClick={() => navigate(routeNames.termsAndConditions)}>
              {t('termsAndConditions')}
            </div>
            <div className={styles.privacyPolicy} onClick={() => navigate(routeNames.privacyPolicy)}>
              {t('privacyPolicy')}
            </div>
          </Row>
        </Row>
      </div>
    </>
  );
}

export default ProfileTemplate;
