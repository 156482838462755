export const endpoints = {
  login: '/authentication/login',
  refreshToken: '/authentication/refreshToken',
  forgotPassword: '/users/forgotPassword',
  checkOTP: '/users/checkToken',
  checkSignUpOTP: '/users/checkOTP',
  resetPassword: '/users/resetPassword',
  resendOTP: '/users/resendOTP',
  getLocation: '/geocode/v1/json',
  signUp: '/users/create',
  loginWithGoogle: '/authentication/google',
  loginWithFacebook: '/authentication/facebook',
  loginWithApple: '/authentication/apple',
  getCategories: '/categories',
  getCategoriesList: '/categories/dynamic-forms',
  getTalents: '/talents',
  getTalentById: '/talents/details',
  requestTalent: '/requests',
  favoriteCRUD: '/favorites',
  profileCRUD: '/users/profile',
  deleteAccount: '/users',
  checkSubscription: '/subscription/current',
  checkSubscriptionHistory: '/subscription/history',
  getLovsCountries: '/lov/countries',
  getLovsCities: '/lov/cities',
  getLovsLanguages: '/lov/languages',
  getLovsEyes: '/lov/eyes',
  getLovsSkin: '/lov/skin',
  getLovsHair: '/lov/hair',
  getLovsTalentTypes: '/lov/talentType',
  getPagePerType: '/pages',
  logout: '/',
  submissionsEndpoint: '/submissions',
  publishSubmission: '/submissions/publish',
  unpublishSubmission: '/submissions/unpublish',
  getCategoryForm: '/dynamic-forms',
  updateLanguage: '/users/app-language',
  notifications: '/notifications/history',
};
