import React, { Suspense, useMemo } from 'react';
import classNames from 'classnames';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MBCLogo from 'assets/images/MBCLogo.svg';
import styles from './NonAuthTemplate.module.scss';
import SVGButton from 'components/atoms/SVGButton/SVGButton';
import GlobeIcon from 'components/icons/GlobeIcon';

const NonAuthTemplate = () => {
  const { t, i18n } = useTranslation();

  const lang = useMemo(() => {
    return i18n.language === 'ar' ? 'en-US' : 'ar';
  }, [i18n.language]);

  return (
    <div className={classNames(styles.nonAuthContainer, 'align-items-center align-content-center flex-wrap')}>
      <div className={classNames(styles.illustrationContainer)}>
        <img src={MBCLogo} alt="logo" className={classNames(styles.logo, 'no-rotate')} />
      </div>
      <div className={classNames(styles.content, 'justify-content-center align-items-center flex-wrap')}>
        <Suspense>
          <Outlet />
        </Suspense>
        <div className={styles.languageSwitcher}>
          <SVGButton
            label={t('language')}
            variant="none"
            onClick={() => i18n.changeLanguage(lang)}
            className={styles.languageButton}
            ComponentSvg={GlobeIcon}
          />
        </div>
      </div>
    </div>
  );
};

export default NonAuthTemplate;
