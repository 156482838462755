import React from 'react';

function ReadMoreIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
      <path
        stroke="#363D4E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21.094 4.688H3.906a.781.781 0 00-.781.78v14.063c0 .432.35.782.781.782h17.188c.431 0 .781-.35.781-.782V5.47a.781.781 0 00-.781-.782zM7.422 9.375h10.156M7.422 12.5h10.156M7.422 15.625h10.156"
      ></path>
    </svg>
  );
}

export default ReadMoreIcon;
