import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-chained-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { backEnds, backEndOptions } from 'utils/i18n';
import config from './config';

if (config.isDevEnv) {
  window.i18n = i18n; // useful for debugging
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      backends: backEnds,
      backendOptions: backEndOptions,
    },
    ns: ['common'],
    defaultNS: 'common',
    fallbackLng: ['en', 'ar'],
    debug: config.isDevEnv,
    load: 'languageOnly',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
