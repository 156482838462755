import BellIcon from 'components/icons/BellIcon';
import CreditCardIcon from 'components/icons/CreditCardIcon';
import GlobeIcon from 'components/icons/GlobeIcon';
import SettingsIcon from 'components/icons/SettingsIcon';
import Submission from 'components/icons/Submission';
import { useAppSelector } from 'hooks/useAppSelector';
import { routeNames } from 'hooks/useRoutes';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export interface ILink {
  label: string;
  value: string;
  link: string;
  enabled?: boolean;
  exact?: boolean;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export const useProfileLinks = () => {
  const { t } = useTranslation('profile');
  const { freeSubscription } = useAppSelector((s) => s.subscription);

  return useMemo((): ILink[] => {
    return [
      {
        label: t('links.accountDetails'),
        value: routeNames.profile,
        link: routeNames.profile,
        enabled: true,
        icon: SettingsIcon,
      },
      {
        label: t('links.requestedTalents'),
        value: routeNames.requestedTalents,
        link: routeNames.requestedTalents,
        enabled: true,
        icon: BellIcon,
      },
      {
        label: t('links.mySubscription'),
        value: routeNames.mySubscriptions,
        link: routeNames.mySubscriptions,
        enabled: !freeSubscription,
        icon: CreditCardIcon,
      },
      {
        label: t('links.submissions'),
        value: routeNames.submissions,
        link: routeNames.submissions,
        enabled: true,
        icon: Submission,
      },
      {
        label: t('links.languages'),
        value: routeNames.languages,
        link: routeNames.languages,
        enabled: true,
        icon: GlobeIcon,
      },
    ];
  }, [freeSubscription, t]);
};
