import React from 'react';
interface IProps {
  className?: string;
  onClick?: () => void;
}

const BackArrow: React.FC<IProps> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15.625 4.688L7.812 12.5l7.813 7.813"
      ></path>
    </svg>
  );
};

export default BackArrow;
